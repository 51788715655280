var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-12 text-center border-dashed",attrs:{"cols":"12","sm":"6"},on:{"drop":function($event){$event.preventDefault();return _vm.onDrop($event)},"dragover":function($event){$event.preventDefault();}}},[_c('p',[_vm._v("Drag & drop files here")]),_c('p',[_vm._v("or")]),_c('ValidationProvider',{ref:"validationProvider",attrs:{"mode":"eager","name":_vm.name,"immediate":_vm.immediate,"rules":_vm.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('v-file-input',{attrs:{"disabled":_vm.disabled,"value":_vm.value,"outlined":"","error":errors.length > 0,"error-messages":_vm.errorMessages
          .concat(_vm.dropErrors)
          .concat(
            errors.length && !_vm.value
              ? _vm.required
                ? ['You must upload a file']
                : []
              : errors
          ),"prepend-icon":"","clearable":!_vm.multiple,"truncate-length":"32","accept":_vm.fileTypesString,"label":"Browse files","data-cy":"File upload","multiple":_vm.multiple},on:{"change":function () {
          var args = [], len = arguments.length;
          while ( len-- ) args[ len ] = arguments[ len ];

          _vm.onChange(args, true)
          validate(args[0])
        }}})]}}])}),_c('ValidationMessages',{attrs:{"value":_vm.warnings,"color":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }