























































import ValidationMessages from '../ValidationMessages.vue'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationProviderType } from '@/types/validation'
import { ValidationProvider } from 'vee-validate'
import { lookup } from 'mime-types'

@Component({
  components: {
    ValidationMessages,
    ValidationProvider,
  },
})
export default class FileUpload extends Vue {
  @Prop({
    default: false,
  })
  disabled!: boolean

  @Prop({
    type: [File, Array],
    default: null,
  })
  value!: File | File[] | null

  @Prop({
    type: Array,
    default: () => [],
  })
  errorMessages!: string[]

  @Prop({
    type: Array,
    default: () => ['*'],
  })
  fileTypes!: string[]

  @Prop({
    type: Boolean,
  })
  multiple!: boolean

  @Prop({
    type: Boolean,
  })
  required!: boolean

  @Prop({
    type: Boolean,
  })
  immediate!: boolean

  @Prop({
    type: String,
    required: true,
  })
  name!: string

  dropErrors: string[] = []

  warnings: string[] = []

  internalNumberOFiles = 0

  get fileTypesString() {
    return this.fileTypes.join(', ')
  }

  mounted() {
    if (this.immediate) {
      ;((this.$refs
        .validationProvider as unknown) as ValidationProviderType).setFlags({
        dirty: true,
      })
    }
  }

  get filesAsArray() {
    return Array.isArray(this.value)
      ? this.value
      : this.value
      ? [this.value]
      : []
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrop(e: any) {
    if (!this.disabled) {
      this.dropErrors = []
      const files = Object.values(e.dataTransfer.files) as File[]
      const mimeTypeCompliantFiles = files.filter(file =>
        this.fileTypes.some(t => {
          const mimeTypeUntilAsterisk = t.substring(
            0,
            t.indexOf('*') === -1 ? t.length : t.indexOf('*'),
          )
          if (file.type.startsWith(mimeTypeUntilAsterisk)) return true
          else if (file.type === '') {
            // chrome on windows gives us this behaviour
            const mimeType = lookup(file.name)
            if (mimeType !== false)
              return mimeType.startsWith(mimeTypeUntilAsterisk)
          }
          return false
        }),
      )
      if (mimeTypeCompliantFiles.length < files.length)
        this.dropErrors.push(
          `Files must be${this.fileTypes.length > 1 ? ' one of' : ''}: ${
            this.fileTypesString
          }`,
        )
      if (mimeTypeCompliantFiles.length)
        this.emit(mimeTypeCompliantFiles, false)
      this.$nextTick(() => {
        ;((this.$refs
          .validationProvider as unknown) as ValidationProviderType).validate()
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange(args: any[], clearDropErrors = false) {
    // handle blur vs input event
    if (Array.isArray(args)) {
      if (args[0] instanceof File) {
        // blur event
        this.$emit('input', this.multiple ? this.filesAsArray : args[0], false)
      } else {
        // input event
        this.emit(args[0], clearDropErrors)
      }
    }
  }

  emit(file: File | File[], clearDropErrors: boolean) {
    this.dropErrors = clearDropErrors ? [] : this.dropErrors
    this.warnings = []

    let filesArray = Array.isArray(file) ? file : file ? [file] : []
    const originalLength = filesArray.length

    if (!this.multiple && filesArray.length > 1) {
      this.warnings.push('Only one file can be uploaded')
      filesArray = filesArray.slice(0, 1)
    }

    const fileNames = filesArray.map(f => f.name)
    const existingFiles = this.filesAsArray.filter(
      f => !fileNames.includes(f.name),
    )

    const replacedFiles = this.filesAsArray
      .filter(f => fileNames.includes(f.name))
      .map(f => f.name)
    if (replacedFiles.length)
      this.warnings.push(
        `Updated file${
          replacedFiles.length > 1 ? 's:' : ''
        } ${replacedFiles.join(', ')}`,
      )

    const emitValue = this.multiple
      ? existingFiles.concat(filesArray)
      : filesArray.length
      ? filesArray[0]
      : null

    this.internalNumberOFiles = Array.isArray(emitValue)
      ? emitValue.length
      : emitValue
      ? 1
      : 0

    if (emitValue !== null || this.multiple || originalLength === 0) {
      this.$emit('input', emitValue)
    }
  }

  @Watch('value')
  onValueChange(newValue: File | File[] | null) {
    if (!this.multiple) return
    else if (newValue === null) this.warnings = []
    else {
      const filesArray = Array.isArray(newValue) ? newValue : [newValue]
      if (filesArray.length < this.internalNumberOFiles) {
        this.internalNumberOFiles = filesArray.length
        this.warnings = []
      }
    }
  }
}
