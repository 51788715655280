export enum FinanceTimeframe {
  UNCONDITIONAL = 0,
  FORTNIGHT = 14,
  MONTH = 30,
  MONTH_AND_A_HALF = 45,
  THREE_MONTH = 90,
  FOUR_MONTH = 120,
}

export const FinanceTimeframeDescription: Record<
  string | FinanceTimeframe,
  FinanceTimeframe | string
> = {
  [FinanceTimeframe.UNCONDITIONAL]: '0 days (unconditional finance)',
  [FinanceTimeframe.FORTNIGHT]: '14 days',
  [FinanceTimeframe.MONTH]: '30 days',
  [FinanceTimeframe.MONTH_AND_A_HALF]: '45 days',
  [FinanceTimeframe.THREE_MONTH]: '90 days',
  [FinanceTimeframe.FOUR_MONTH]: '120 days',
}
